import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { formatNumberWithDollar } from "../../../../utils/numberFormater";
import EditScenarioDialog from "./edit-scenario-dialog";

const generateTitleByScenarioType = (scenarioItem) => {
  const { scenarioType = "", lvrSetting = {} } = scenarioItem;
  const { estimateProperty = 0, cashTowardProperty } = lvrSetting;
  switch (scenarioType) {
    case "buy-a-house":
      return [
        "Owner Occupier | Max Loan",
        `${formatNumberWithDollar(cashTowardProperty)} Deposit`,
      ];
    case "buy-an-investment":
      return [
        "Investment Property | Buying Goal",
        `Buying Goal ${formatNumberWithDollar(estimateProperty)}`,
      ];
    case "refinance-home-loan":
      return [
        "Refinance My Home Loan",
        `Loan Amount ${formatNumberWithDollar(cashTowardProperty)}`,
      ];
    default:
      return ["See My Max Buying Power", "Max Buying Power"];
  }
};

const getScenarioDetail = (scenarioItem) => {
  const [title, description] = generateTitleByScenarioType(scenarioItem);
  return {
    scenarioTitle: title,
    scenarioDescription: description,
  };
};

const ScenarioItem = ({
  scenarioDetail = {},
  selectedScenario,
  handleSelectScenario,
  isShowEditScenarioDialog,
}) => {
  const { id = "", scenarioObject = {} } = scenarioDetail;
  const handleOnClickScenario = (value) => {
    handleSelectScenario(value);
  };
  const { scenarioTitle = "", scenarioDescription = "" } =
    getScenarioDetail(scenarioDetail?.scenarioObject) || {};

  return (
    <div
      onClick={() => handleOnClickScenario(id)}
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
      className={classNames("la-container", {
        selected: id === selectedScenario,
      })}
    >
      <div>
        {isShowEditScenarioDialog && (
          <EditScenarioDialog scenarioDetail={scenarioObject} />
        )}
        <div className="bc-title mb-2 font-weight-bold">{scenarioTitle}</div>
        <div className="bc-value">{scenarioDescription}</div>
      </div>
    </div>
  );
};

ScenarioItem.propTypes = {
  scenarioDetail: PropTypes.object,
  selectedScenario: PropTypes.string,
};

export default ScenarioItem;
