import { withStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useContext, useState } from "react";
import ScenarioItemContext from "../../../../context/scenario-item-context";
import { ThreeDot } from "../../../../images";
import RemoveScenarioButton from "./remove-scenario-button";

const StyledMenuItem = withStyles({
  root: {
    fontSize: 14,
    fontFamily: "ProximaNovaRegular",
    justifyContent: "center",
    textAlign: "center",
  },
})(MenuItem);

const EditScenarioDialog = ({ scenarioDetail = {} }) => {
  const { setScenarioFilterOpen, setScenarioFilterData } = useContext(
    ScenarioItemContext
  );
  const [openMenu, setOpenMenu] = useState(null);
  const handleDialogToggle = (value) => {
    setOpenMenu(value);
  };
  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };
  const handleUpdateScenario = () => {
    setScenarioFilterData(scenarioDetail);
    handleDialogToggle(false);
    setScenarioFilterOpen(true);
  };
  return (
    <>
      <div
        onClick={handleClick}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        className="three-dots-icon"
      >
        <img src={ThreeDot} alt="" />
      </div>

      <Menu
        id="long-menu"
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={() => handleDialogToggle(false)}
        PaperProps={{
          style: {
            fontFamily: "ProximaNovaRegular",
            fontSize: 14,
            width: "20ch",
          },
        }}
      >
        <StyledMenuItem>
          <div
            className="w-100"
            onClick={() => {
              handleUpdateScenario();
            }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            Update Scenario
          </div>
        </StyledMenuItem>
        <StyledMenuItem className="remove-property">
          <RemoveScenarioButton handleEditDialogToggle={handleDialogToggle} />
        </StyledMenuItem>
      </Menu>
    </>
  );
};

export default EditScenarioDialog;
