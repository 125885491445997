import PropTypes from "prop-types";
import React, { useContext } from "react";
import ScenarioItemContext from "../../../../context/scenario-item-context";

const RemoveScenarioButton = ({ handleEditDialogToggle = null }) => {
  const { setDialogOpen } = useContext(ScenarioItemContext);
  const handleDialogToggle = (value) => {
    handleEditDialogToggle(false);
    setDialogOpen(value);
  };
  return (
    <>
      <div
        className="w-100"
        role="button"
        onClick={() => handleDialogToggle(true)}
        onKeyDown={() => {}}
        tabIndex={0}
      >
        <div>Delete Scenario</div>
      </div>
    </>
  );
};

RemoveScenarioButton.propTypes = {
  handleEditDialogToggle: PropTypes.func,
};

export default RemoveScenarioButton;
